<script>
import svgClose from "@/svg/close";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "settings/getSetting",
  mutationType: "settings/updateSetting",
});

export default {
  name: "app-settings",

  components: {
    svgClose,
  },

  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapFields(["new_tab_for_listings"]),
  },

  methods: {
    exit() {
      this.$emit("input", false);
    },
    escapeEvent(event) {
      if (event.which == 27) this.exit();
    },
  },

  watch: {
    $route() {
      this.exit();
    },
    value(value) {
      if (value) {
        document.addEventListener("keyup", this.escapeEvent);
      } else {
        document.removeEventListener("keyup", this.escapeEvent);
      }
    },
  },
};
</script>

<template>
  <div id="app-settings" :class="{ active: value }">
    <div class="overlay" @click="exit"></div>
    <div class="wrapper">
      <div class="bar">
        <button type="button" class="button close" @click="exit()">
          <svgClose />
        </button>
      </div>
      <b-switch v-model="new_tab_for_listings"
        >Abrir propiedades en una nueva pestaña</b-switch
      >
    </div>
  </div>
</template>

<style lang="scss">
#app-settings {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.75);
  }
  .wrapper {
    position: absolute;
    width: 17.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    transform: translate3d(100%, 0, 0);
    transition: 0.1s all ease-out;
    padding: 1.75rem;
    @media (min-width: 40em) {
      width: 18.75rem;
    }
  }
  &.active {
    left: 0;
    .wrapper {
      transform: translate3d(0, 0, 0);
    }
  }

  .bar {
    display: flex;
    justify-content: flex-end;
  }

  .close {
    display: block;
    width: 1rem;
    height: 1rem;
    // padding: 0.875rem;
    padding: 0;
    margin-bottom: 0.75rem;
    border: none;
  }
}
</style>
